ul {
  list-style: none;
  margin: 0;
}

.dropdown-btn-container {
  margin: 0 auto;
  background: rgb(145,65,174);
  background: linear-gradient(54deg, rgba(145,65,174,1) 0%, rgba(128,73,148,1) 17%, rgba(151,83,128,1) 52%, rgba(186,74,89,1) 84%, rgba(244,83,105,1) 100%);
  height: auto;
  border-radius: 14px;
  padding: 2px 0;
  width: 200px !important;
    @include breakpoint(medium) {
      margin:0;
      float: right;
    }
  ul.accordion-menu {
    background-color: black;
    border-radius: 12px;
    width: 196px;
    margin: 0 auto;
    padding: 10px;
    li {
      color: $font-color-light;
      font-family: $font-family;
      font-size: 18px;
      font-weight: bold;
      a {
        color: $font-color-light;
        margin: 0 auto;
        padding: 20px 30px;
      }
      a.btn-title-grab {
        padding:20px 20px;
      }
      a.btn-title-select {
        padding:20px 24px;
      }
    }
  }
}

//individual button styles

#grab-shirt-btn {
  margin-top: 30px;
}
#select-size-btn {
  margin-top: 10px;
}

#buy-group-top .dropdown-btn-container {
  float: none;
  margin:0 auto;
}





//size buttons
ul.vertical.nested {
  margin: 5px 0 0 -20px;
  padding: 0;
  li {
    background: rgb(15,15,15);
    background: -moz-linear-gradient(24deg, rgba(15,15,15,1) 0%, rgba(57,57,57,1) 100%);
    background: -webkit-linear-gradient(24deg, rgba(15,15,15,1) 0%, rgba(57,57,57,1) 100%);
    background: linear-gradient(24deg, rgba(15,15,15,1) 0%, rgba(57,57,57,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f0f0f",endColorstr="#393939",GradientType=1);
    display: inline-block;
    width: 196px;
    padding: 15px 0px 15px 42px;
    margin: 1px 0 0 -26px;
  }
  li:hover {
    cursor: pointer;
    background: rgb(15,15,15);
    background: -moz-linear-gradient(24deg, rgba(15,15,15,1) 0%, rgba(83,83,83,1) 100%);
    background: -webkit-linear-gradient(24deg, rgba(15,15,15,1) 0%, rgba(83,83,83,1) 100%);
    background: linear-gradient(24deg, rgba(15,15,15,1) 0%, rgba(83,83,83,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0f0f0f",endColorstr="#535353",GradientType=1);
  }
}

// arrow on dropdown btn
.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 7px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: $font-color-light transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: -1rem;
}