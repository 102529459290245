$font-color-light: #fefefe;
$font-family-condensed: 'Roboto Condensed', 'HelveticaNeue-Condensed',sans-serif;
$font-family: 'Roboto', 'HelveticaNeue-Condensed',sans-serif;
$background-color-dark:  rgb(29,31,36);

body {
  background-color: $background-color-dark;
}

// this makes a fixed background image for iOS devices
body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-color: $background-color-dark;
  background: url(../img/code-background.jpg) no-repeat top left;
   @include breakpoint(medium) {
     background: url(../img/code-bg-med-up.jpg) no-repeat top left;
   }
}

p {
  font-family: $font-family-condensed;
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
  color: $font-color-light;
  text-align: center;
  line-height: 1.3;
    @include breakpoint(medium) {
      font-size: 24px;
    }
}

h1 {
  font-family: $font-family-condensed;
  font-size: 22px;
  font-weight: bold;
  font-style: italic;
  color: $font-color-light;
  text-align: center;
  line-height: 1.3;
    @include breakpoint(medium) {
      font-size: 34px;
      margin-bottom: 40px;
      text-shadow: 0px 0px 50px rgba(0,0,0,1), 0px 0px 40px rgba(0,0,0,1), -30px 0px 40px rgba(0,0,0,1), 30px 0px 40px rgba(0,0,0,1),;
    }
}

// .grid-container {
//   background: fixed;
//   background-image: url(../img/code-background.jpg);
// }

.fouc-sticky.sticky.is-stuck {
  z-index: 0;
}

.gradient-overlay-container {
  margin-top: -400px;
}

.gradient-overlay {
  background: rgb(29,31,36);
  background: -moz-linear-gradient(0deg, rgba(29,31,36,1) 0%, rgba(29,31,36,1) 55%, rgba(29,31,36,0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(29,31,36,1) 0%, rgba(29,31,36,1) 55%, rgba(29,31,36,0) 100%);
  background: linear-gradient(0deg, rgba(29,31,36,1) 0%, rgba(29,31,36,1) 55%, rgba(29,31,36,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d1f24",endColorstr="#1d1f24",GradientType=1);
  height: 100vh;
}

.gradient-overlay.sticky.is-stuck {
  z-index: auto;
}

// buy-group contains the paragraph and buy button
.buy-group {
  z-index: 1;
  width: auto;
  margin: -300px auto 100vh;
  @include breakpoint(medium) {
    max-width: 900px;
    min-width: 300px;
    margin: -700px auto 1000px;
    padding: 0 90px;
  }
}

//this effects the top buy group for the text to be larger and centered over the button there is also styles in the _buy-btn.scss that need to be adjusted also for the button
#buy-group-top {
  z-index: 1;
  width: auto;
  margin: -300px auto 100vh;
  min-width: none;
  max-width: none;
  padding: none;
  .text-bnt-container {
    float: none;
    @include breakpoint(medium) {
      max-width: 400px;
    }
  }
  @include breakpoint(medium) {
    margin: -700px auto 1200px;
  }
}

.buy-group.bg-bottom {
  margin-bottom: 70vh;
}

.text-bnt-container {
  padding: 0;
  max-width: 280px;
  margin: 0 auto;
  @include breakpoint(medium) {
    float: left;
  }
}